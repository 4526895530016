(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("js-cookie"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "js-cookie"], factory);
	else if(typeof exports === 'object')
		exports["CustomerApi"] = factory(require("axios"), require("js-cookie"));
	else
		root["CustomerApi"] = factory(root["axios"], root["js-cookie"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__3300__, __WEBPACK_EXTERNAL_MODULE__6598__) {
return 